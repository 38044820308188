<template>
	<!-- 调账表单 -->
	<div>
		<el-dialog
			title="调账"
			:visible.sync="open"
			width="700px"
			@close="commitOnClose"
		>
			<el-form
				ref="saveOrEditForm"
				:model="saveData"
				label-width="150px"
				style="width:600px"
				:rules="rules"
			>
				<el-form-item label="代理商" prop="agentNo">
					<el-select
						style="width:100%"
						v-model="agentNo"
						filterable
						remote
						reserve-keyword
						@change="getUserName"
						placeholder="可根据代理商编号模糊查询"
						:remote-method="remoteMethod"
					>
						<el-option
							v-for="item in options"
							:key="item.id"
							:label="item.agentNo"
							:value="item"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="代理商名称" prop="userName">
					{{ saveData.userName }}
				</el-form-item>
				<el-form-item label="调整账户" prop="accountType">
					<el-select
						v-model="saveData.accountType"
						placeholder="请选择调整账户"
						style="width: 240px"
					>
						<el-option
							v-for="(item, key) in dicts.ACCOUNT_TYPE"
							:key="item"
							:label="item"
							:value="key"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="调账类型" prop="adjustmentType">
					<el-select
						v-model="saveData.adjustmentType"
						placeholder="请选择调账类型"
						style="width: 240px"
					>
						<el-option
							v-for="(item, key) in dicts.ADJUSTMENT_TYPE"
							:key="item"
							:label="item"
							:value="key"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="上游通道" prop="channelNo">
					<el-select
						v-model="saveData.channelNo"
						placeholder="上游通道"
						style="width: 240px"
					>
						<el-option
							v-for="item in acqChannelList"
							:label="item.name"
							:value="item.channelNo"
							:key="item.channelNo"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="调整金额" prop="changeMoney">
					<el-input
						v-model="saveData.changeMoney"
						style="width:400px"
						v-numberInt:2
						placeholder="请输入调整金额"
					/>
					元
				</el-form-item>
				<el-form-item label="备注">
					<el-input
						v-model="saveData.remark"
						type="textarea"
						maxlength="100"
						placeholder="请最多输入100字"
					/>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitCheck">确定</el-button>
				<el-button type="primary" @click="cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { FinancialToolsApi, TerminalApi } from "@/api";
export default {
	name: "CustomForm",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		isAdd: {
			type: Boolean,
			default: true
		},
		selectRow: {
			type: Object,
			default: function() {
				return {};
			}
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			title: "",
			saveData: {},
			open: false,
			rules: {
				agentNo: [
					{
						required: true,
						message: "请选择代理商",
						trigger: "change",
						type: "string"
					}
				],
				accountType: [
					{
						required: true,
						message: "请选择调整账户",
						trigger: "change",
						type: "string"
					}
				],
				adjustmentType: [
					{
						required: true,
						message: "请选择调账类型",
						trigger: "change",
						type: "string"
					}
				],
				channelNo: [
					{
						required: true,
						message: "请选择上游通道",
						trigger: "change",
						type: "string"
					}
				],
				changeMoney: [
					{
						required: true,
						message: "请输入调整金额",
						trigger: "blur"
					}
				]
			},
			acqChannelList: [],
			options: [],
			agentNo: ""
		};
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
			if (value) {
				this.resetData();
			}
		}
	},
	async mounted() {
		const result = await TerminalApi.acqChannel.listAllChannel();
		this.acqChannelList = (result && result.data) || [];
	},
	methods: {
		async remoteMethod(query) {
			if (query != "") {
				let res = await FinancialToolsApi.suspendOrder.queryAgentInfo(
					query
				);
				if (res.success) {
					this.options = res.data;
				}
			} else {
				this.options = [];
			}
		},
		submitCheck() {
			this.$refs.saveOrEditForm.validate(valid => {
				if (valid) {
					this.submitForm();
				} else {
					return false;
				}
			});
		},
		async resetData() {
			if (this.isAdd) {
				this.title = "新增止付";
				this.saveData = {};
			} else {
				this.saveData = this.selectRow;
				this.isAdd = false;
				this.title = "修改止付";
			}
		},
		async submitForm() {
			let result = await FinancialToolsApi.agentAccount.insertAdjustment(
				this.saveData
			);
			if (result.success) {
				this.Message.success("添加成功");
				this.saveData.userName = "";
				this.saveData.agentNo = "";
				this.saveData.accountType = "";
				this.saveData.adjustmentType = "";
				this.saveData.channelNo = "";
				this.saveData.changeMoney = "";
				this.saveData.remark = "";
				this.agentNo = "";
				this.commitOnClose();
			}
		},
		cancel() {
			this.saveData = {};
			this.saveData.userName = "";
			this.saveData.agentNo = "";
			this.saveData.accountType = "";
			this.saveData.adjustmentType = "";
			this.saveData.channelNo = "";
			this.saveData.changeMoney = "";
			this.saveData.remark = "";
			this.agentNo = "";
			this.commitOnClose();
		},
		commitOnClose() {
			// 清空校验规则
			this.$nextTick(() => {
				this.$refs.saveOrEditForm.resetFields();
			});
			this.$emit("on-close");
		},
		getUserName(item) {
			this.saveData.userName = item.userName;
			this.saveData.agentNo = item.agentNo;
			this.agentNo = item.agentNo;
		}
	}
};
</script>

<style scoped></style>
